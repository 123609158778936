<template>
  <div>
    <header-slot>
      <template #actions>
        <portal-target name="header-actions" />
        <button
          v-if="routerName == 'sn-s-source'"
          type="button"
          class="btn btn-success"
          @click="createSource"
        >
          + CREATE
        </button>
      </template>
    </header-slot>
    <b-nav v-if="tabs" card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'sn-s-motives' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="isActiveTab(1)"
      >
        REASONS
      </b-nav-item>
      <b-nav-item
        :to="{
          name: 'sn-s-source',
        }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="isActiveTab(2)"
      >
        SOURCES
      </b-nav-item>
      <b-nav-item
        :to="{
          name: 'sn-s-team-rank',
        }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="isActiveTab(3)"
      >
        TEAM RANK
      </b-nav-item>
      <b-nav-item
        :to="{
          name: 'sn-s-appointment-sms',
        }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="isActiveTab(4)"
      >
        Appointments SMS
      </b-nav-item>
    </b-nav>
    <b-card
      :class="
        tabs ? 'border-top-primary border-3 border-table-radius px-0' : ''
      "
    >
      <router-view ref="routerView" />
    </b-card>
  </div>
</template>

<script>
import { canNavigateDirect } from "@/libs/acl/routeProtectionDirect";
import { getUserData } from "@/auth/utils";
export default {
  data() {
    return {
      tabs: false,
    };
  },
  computed: {
    routerName() {
      return this.$route.matched[2].name;
    },
    subTab() {
      return this.$route.meta.tab;
    },
  },
  methods: {
    isActiveTab(tab) {
      if (tab == 1 && this.routerName == "sn-s-motives") {
        return true;
      } else if (tab == 2 && this.routerName == "sn-s-source") {
        return true;
      } else if (tab == 3 && this.routerName == "sn-s-team-rank") {
        return true;
      } else if (tab == 4 && this.routerName == "sn-s-appointment-sms") {
        return true;
      }
      return false;
    },
    createSource() {
      if (this.subTab == 1) {
        this.$refs.routerView.createSource();
      } else if (true) {
        this.$refs.routerView.createContactMethod();
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    // Check if user can navigate directly to the specified route (using user's role and software engineering level)
    const userData = getUserData();
    if (!canNavigateDirect(to, userData.arrRoles, userData.eng_soft)) {
      return next({ name: "misc-not-authorized" });
    } else {
      return next();
    }
  },
  created() {
    const userData = getUserData();
    if (
      userData.role_id === 1 ||
      userData.role_id === 11 ||
      userData.role_id === 2
    ) {
      this.tabs = true;
    } else {
      this.tabs = false;
    }
  },
};
</script>