<template>
  <div>
    <header-slot>
      <template #actions>
        <b-button
          v-if="permittedRoles.includes(currentUser.role_id) && currentUser.modul_id == 27"
          variant="outline-success"
          @click="openModalNewRequest"
        >
          <b-icon icon="plus" /> NEW REQUEST 
        </b-button>
      </template>
    </header-slot>
    <b-nav
      pills
      class="m-0"
    >
      <b-nav-item
        v-for="(tab, index) in tabs"
        :key="index"
        :to="{ name: tab.route }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        {{ tab.name }}
        <span
          v-if="tab.counter > 0"
          class="ml-2"
        >
          <feather-icon
            icon
            :badge="
              tab.counter > 99
                ? '99+'
                : tab.counter
            "
            badge-classes="badge-danger badge-glow"
          />
        </span>
      </b-nav-item>
    </b-nav>
    <b-card class="border-top-primary border-3 border-table-radius px-0">
      <router-view
        ref="routerView"
        :key="$route.name + numKey "
        @openModalNewRequest="openModalEditRequest"
        @openModalViewRequest="openModalViewRequest"
        @openModalOnlyViewRequest="openModalOnlyViewRequest"
        @currentTabStatus="currentTabStatus"
      />
    </b-card>
    <request-modal
      v-if="showModal"
      :type-modal="typeModal"
      :is-module="isModule"
      :id-request="idRequest"
      :currentTabStatus="tabSelected"
      @closeModal="closeModal"
      @refreshTable="refreshTable"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import RequestModal from '@/views/creative/views/creative-requests/components/modals/RequestModal.vue';

export default {
  components: {
    RequestModal,
  },
  data() {
    return {
      showModal: false,
      routeName: '',
      numKey: 1,
      typeModal: 'NEW',
      idRequest: null,
      isModule: null,
      tabSelected: null,
      permittedRoles: [1, 2, 17, 18, 19],
    };
  },
  computed: {
    ...mapState({
      S_COUNTER_PENDING: state => state.CreativeDesignStore.S_COUNTER_PENDING,
      S_COUNTER_IN_PROCESS: state => state.CreativeDesignStore.S_COUNTER_IN_PROCESS,
      S_COUNTER_FINISHED: state => state.CreativeDesignStore.S_COUNTER_FINISHED,
      S_COUNTER_APPROVED: state => state.CreativeDesignStore.S_COUNTER_APPROVED,
      S_COUNTER_REMARKS: state => state.CreativeDesignStore.S_COUNTER_REMARKS,
    }),
    ...mapGetters({
      skin: 'appConfig/skin',
      currentUser: 'auth/currentUser',
    }),
    nameModule() {
      return this.$route.meta.moduleName;
    },
    tabs() {
      const tabNamesRoutes = [
        {
          route: `${this.nameModule}-pending`,
          name: 'PENDING',
          counter: this.S_COUNTER_PENDING,
        },
        {
          route: `${this.nameModule}-in-process`,
          name: 'IN PROCESS',
          counter: this.S_COUNTER_IN_PROCESS,
        },
        {
          route: `${this.nameModule}-observed`,
          name: 'REMARKS',
          counter: this.S_COUNTER_REMARKS,
        },
        {
          route: `${this.nameModule}-finished`,
          name: 'FINISHED',
          counter: this.S_COUNTER_FINISHED,
        },
        {
          route: `${this.nameModule}-approved`,
          name: 'APPROVED',
          counter: this.S_COUNTER_APPROVED,
        },
      ];
      if (this.isParagonDesigner) {
        return tabNamesRoutes.slice(1);
      }
      return tabNamesRoutes;
    },
  },
  created() {
    this.routeName = this.$route.name;
  },
  mounted() {
    if (this.isParagonDesigner && this.routeName === `${this.nameModule}-pending`) {
      this.$router.push({
        name: `${this.nameModule}-in-process`,
      });
    }
  },
  methods: {
    currentTabStatus(tab){
      this.tabSelected = tab;
    },
    closeModal() {
      console.log('closeModal');
      this.showModal = false;
    },
    refreshTable() {
      this.numKey += 1;
    },
    openModalNewRequest() {
      if(this.permittedRoles.includes(this.currentUser.role_id) && this.currentUser.modul_id == 27) {
        this.typeModal = 'NEW';
        this.showModal = true;
      } else {
        console.log('No tienes permiso para crear una nueva solicitud');
      }
    },

    openModalEditRequest(design_request_id) {
      this.typeModal = 'EDIT';
      this.idRequest = design_request_id;
      this.showModal = true;
    },
    openModalViewRequest(design_request_id) {
      this.typeModal = 'EDIT';
      this.idRequest = design_request_id;
      this.isModule = this.currentUser.modul_id;
      this.showModal = true;
    },
    openModalOnlyViewRequest(design_request_id) {
      this.typeModal = 'ONLY_VIEW';
      this.idRequest = design_request_id;
      this.isModule = this.currentUser.modul_id;
      this.showModal = true;
    }
  },
};
</script>
